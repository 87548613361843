interface Suggestion {
  id: string
  text: string
  url: string
}
type Suggestions = Suggestion[]

export const useSearch = () => {
  const config = useRuntimeConfig()

  const { languageCode } = useLocale()
  const keyword = ref('')
  const suggestions = ref<Suggestions>([])
  const controller = ref<AbortController | null>(null)

  const getSugesstions = async () => {
    if (controller.value) {
      controller.value.abort()
    }

    if (keyword.value.length < 3) {
      suggestions.value = []
      return
    }
    controller.value = new AbortController()

    try {
      const response: Suggestions = await fetch(
        `${config.public.frontendBaseUrl}/api/elasticsearch/autocomplete?q=${keyword.value}&langcode=${languageCode.value}`,
        {
          signal: controller.value.signal,
        },
      ).then((response) => response.json())

      suggestions.value = response
    } catch (error) {
      console.log(error)
    }
  }
  const search = () => {
    navigateTo({ path: `/search/`, query: { query: keyword.value } })
  }

  return {
    keyword,
    suggestions,
    getSugesstions,
    search,
  }
}
